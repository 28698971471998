/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "../../@core/scss/base/bootstrap-extended/_variables.scss";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');

$blue-dark: #0070ca;
$purple: #3940e5 !default; //$primary

$theme-colors: (
  // "blue": #0071F7,
  "blue": #0094ef,
  "blue-medium": #0071f6,
  "blue-dark": $blue-dark,
  "danger": #d93d2b,
  "gold": #fcc46a,
  "success": #28c76f
);

.scroll-top {
  margin-bottom: 25px;
  border-radius: 20px;
  overflow: hidden;
}

.select__control {
  height: 42px;
}

.select_finance__control {
  height: 42px;
}

.card {
  background-color: white;
}

.card-title {
  color: black;
}

.card {
  border-width: 1px;
  border-color: #e2e2e2;
  box-shadow: none;
  border-radius: 12px;
  overflow: hidden;
  overflow: visible;
}

.content-overlay {
  max-width: 100vw !important;
}

.page-title {
  font-size: 24px !important;
}

@media (max-width: 767px) {
  .wrapper {
    overflow-x: hidden;
  }
}

@media (min-width: 767px) {
  .page-title {
    font-size: 30px !important;
  }
}

// Apply display: none for small devices
@media (max-width: 576) {
  div .hide-sm {
    display: none !important;
  }
}

// Apply display: block for devices larger than 767px
@media (min-width: 576) {
  div .hide-sm {
    display: block !important;
  }
}

@media (max-width: (map-get($grid-breakpoints, xl))) {
  .hide-md {
    display: none;
  }
}

.hide-md {
  display: block;
}

.modal-content {
  background-color: white !important;
}

@media (min-width: (map-get($grid-breakpoints, xl))) {
  .hide-md {
    display: none;
  }
}

.navigation-main li.nav-item a.active {
  color: $primary-dark !important;
  background: none !important;
  // background-color: #ecf2fe !important;
  background-color: $primary-subtle !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  font-family: 'DM Sans', 'Roboto', sans-serif !important;
}

.navigation-main li.nav-item {
  font-family: 'DM Sans', 'Roboto', sans-serif !important;
}

.dropdown-menu {
  border-width: 1px;
  border-color: #ebe9f0;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  // font-family: "euclide-medium", sans-serif;
}

.page-action-container {
  z-index: 999;
  position: absolute;
  right: 10px;
  top: -40px;
}

// span:not(.menu-item) {
// 	color: black !important;
// }
// :not(.btn-group) {
// .btn {
// 	border-radius: 20px !important;
// 	// background-color: $blue-dark !important;
// }
// }

// .btn-group> :first-child {
// 	/* Styles for the first child inside .clas1 */
// 	border-top-left-radius: 20px;
// 	border-bottom-left-radius: 20px;
// 	padding-left: 10px;
// }

// .btn-group> :last-child:not(.dropdown-menu) {
// 	border-top-right-radius: 20px;
// 	padding-right: 10px;
// 	border-bottom-right-radius: 20px;
// 	/* Styles for the last child inside .clas1 */
// }

// @media (max-width: 767px) {
// 	.container-xxl {
// 		padding-bottom: 60px !important;
// 	}
// }

// prettier-ignore 
@media (min-width: 767px) {
  #card-cover-photo {
    // 593 + 40
    min-height: 633px !important;
  }
}

// prettier-ignore 
.form-label {
  font-size: 14px !important;
  color: black;
  font-weight: 500 !important;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (min-width: 767px) {
    font-size: 16px !important;
  }

}

.MuiChip-label {
  font-size: 14px !important;
  // margin-top: 2px;
}

strong {
  color: black !important;
}

// prettier-ignore 
@media (max-width: 767px) {
  .post-image {
    width: 100%;
  }
}

// p,
// div {
// 	font-size: 1.1rem !important;
// }

$breakpoints: (
  "small": 480px,
  "medium": 768px,
  "large": 1024px,
);

// Tamaños de fuente base para cada encabezado
$base-font-sizes: (
  "h1": 24px,
  "h2": 22px,
  "h3": 20px,
  "h4": 18px,
  "h5": 16px,
  "h6": 14px,
  "h7": 12px,
  // Aunque h7 no es un elemento HTML estándar, se incluye para completar el patrón
);

// Tamaños de fuente ajustados para cada breakpoint
$adjusted-font-sizes: (
  "small": ("h1": 26px,
    "h2": 24px,
    "h3": 22px,
    "h4": 20px,
    "h5": 18px,
    "h6": 16px,
    "h7": 14px,
  ),
  "medium": ("h1": 28px,
    "h2": 26px,
    "h3": 24px,
    "h4": 22px,
    "h5": 20px,
    "h6": 18px,
    "h7": 16px,
  ),
  "large": ("h1": 30px,
    "h2": 28px,
    "h3": 26px,
    "h4": 24px,
    "h5": 22px,
    "h6": 20px,
    "h7": 18px,
  ),
);

// Aplica el tamaño de fuente base
// prettier-ignore 
@each $header, $size in $base-font-sizes {
  #{$header} {
    font-size: $size;

    // Itera sobre cada breakpoint
    @each $breakpoint-name, $breakpoint-size in $breakpoints {
      @media (min-width: $breakpoint-size) {
        // Ajusta el tamaño de la fuente basado en el breakpoint
        font-size: map-get(map-get($adjusted-font-sizes, $breakpoint-name), $header);
      }
    }
  }
}

.text-primary {
  color: $purple !important;
}

// .bg-primary {
//   background-color: #00328f !important;
// }

// .bg-purple {
//   background-color: #7367f0 !important;
// }

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.modal-title {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-size: 25px;
}

.form-switch .form-check-label {

  .switch-icon-left,
  .switch-icon-right {
    transform: translate(0px, -1px) !important;
  }
}

// prettier-ignore 
// p {
//   font-size: 15px;
//   color: #6a7786;

//   @media (min-width: 767px) {
//     font-size: 16px;
//   }
// }

// prettier-ignore 
@media screen and (max-width: 767px) {
  .modal-dialog {
    min-height: 100vh !important;
    width: 100vw !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  // .modal-content {
  // 	height: 100vh !important;
  // }

  .modal-header .btn-close {
    margin-right: 10px !important;
  }
}

.scroll-container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

// prettier-ignore 
@media screen and (max-width: 1140px) {
  .navigation-main> :nth-child(10) {
    display: none !important;
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

// .wrapper {
// 	overflow: hidden;
// }

.vertical-layout.vertical-menu-modern .toggle-icon,
.vertical-layout.vertical-menu-modern .collapse-toggle-icon {
  margin-right: 0px;
}

:root {
  --font-size: 20px;
}

// .app-content,
// .footer {
// 	// background-color: #f9f9f9 !important;
// 	background-color: #F1F2F6 !important;
// }

.app-content {
  background-color: transparent !important;
}

.beautiful-bg-1 {
  background-size: 156.45vw 99.03vw, 156.45vw 99.03vw, 156.45vw 99.03vw, 226.86vw 145.44vw,
    226.86vw 145.44vw, 226.86vw 145.44vw, 171.96vw 110.31vw, 171.96vw 110.31vw, 171.96vw 110.31vw,
    130.29vw 83.58vw, 130.29vw 83.58vw, 130.29vw 83.58vw, 198vw 126.9vw, 198vw 126.9vw,
    198vw 126.9vw, 300vw 192vw, 300vw 192vw, 300vw 192vw;
}

.beautiful-bg-2 {
  background-image: radial-gradient(50% 50% at 50% 50%,
      rgba(160, 51, 255, 0.024) 0%,
      rgba(160, 51, 255, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, 0.04) 0%, rgba(160, 51, 255, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, 0.064) 0%, rgba(160, 51, 255, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.024) 0%, rgba(24, 119, 242, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.04) 0%, rgba(24, 119, 242, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.064) 0%, rgba(24, 119, 242, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, 0.024) 0%, rgba(255, 108, 92, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, 0.04) 0%, rgba(255, 108, 92, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, 0.064) 0%, rgba(255, 108, 92, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, 0.024) 0%, rgba(245, 206, 51, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, 0.04) 0%, rgba(245, 206, 51, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, 0.064) 0%, rgba(245, 206, 51, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, 0.024) 0%, rgba(37, 211, 102, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, 0.04) 0%, rgba(37, 211, 102, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, 0.064) 0%, rgba(37, 211, 102, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.024) 0%, rgba(24, 119, 242, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.04) 0%, rgba(24, 119, 242, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.064) 0%, rgba(24, 119, 242, 0) 100%);
}

.beautiful-bg-3 {
  background-size: 156.45vw 99.03vw, 156.45vw 99.03vw, 156.45vw 99.03vw, 226.86vw 145.44vw,
    226.86vw 145.44vw, 226.86vw 145.44vw, 171.96vw 110.31vw, 171.96vw 110.31vw, 171.96vw 110.31vw,
    130.29vw 83.58vw, 130.29vw 83.58vw, 130.29vw 83.58vw, 198vw 126.9vw, 198vw 126.9vw,
    198vw 126.9vw, 300vw 192vw, 300vw 192vw, 300vw 192vw;
}

.beautiful-bg-4 {
  background-image: radial-gradient(50% 50% at 50% 50%,
      rgba(160, 51, 255, 0.024) 0%,
      rgba(160, 51, 255, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, 0.04) 0%, rgba(160, 51, 255, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, 0.064) 0%, rgba(160, 51, 255, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.024) 0%, rgba(24, 119, 242, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.04) 0%, rgba(24, 119, 242, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.064) 0%, rgba(24, 119, 242, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, 0.024) 0%, rgba(255, 108, 92, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, 0.04) 0%, rgba(255, 108, 92, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, 0.064) 0%, rgba(255, 108, 92, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, 0.024) 0%, rgba(245, 206, 51, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, 0.04) 0%, rgba(245, 206, 51, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, 0.064) 0%, rgba(245, 206, 51, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, 0.024) 0%, rgba(37, 211, 102, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, 0.04) 0%, rgba(37, 211, 102, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, 0.064) 0%, rgba(37, 211, 102, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.024) 0%, rgba(24, 119, 242, 0) 50%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.04) 0%, rgba(24, 119, 242, 0) 75%),
    radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, 0.064) 0%, rgba(24, 119, 242, 0) 100%);
}

.beautiful-bg-4 {
  background-position: 37.97vw calc(((300vw - 100vh) / 2 - 85.77vw) * -1),
    37.97vw calc(((300vw - 100vh) / 2 - 85.77vw) * -1),
    37.97vw calc(((300vw - 100vh) / 2 - 85.77vw) * -1),
    -100vw calc(((300vw - 100vh) / 2 - 154.56vw) * -1),
    -100vw calc(((300vw - 100vh) / 2 - 154.56vw) * -1),
    -100vw calc(((300vw - 100vh) / 2 - 154.56vw) * -1),
    13.34vw calc(((300vw - 100vh) / 2 - 53.88vw) * -1),
    13.34vw calc(((300vw - 100vh) / 2 - 53.88vw) * -1),
    13.34vw calc(((300vw - 100vh) / 2 - 53.88vw) * -1),
    10.64vw calc(((300vw - 100vh) / 2 - 17.19vw) * -1),
    10.64vw calc(((300vw - 100vh) / 2 - 17.19vw) * -1),
    10.64vw calc(((300vw - 100vh) / 2 - 17.19vw) * -1),
    -49vw calc(((300vw - 100vh) / 2 - 41.1vw) * -1), -49vw calc(((300vw - 100vh) / 2 - 41.1vw) * -1),
    -49vw calc(((300vw - 100vh) / 2 - 41.1vw) * -1), -100vw calc(((300vw - 100vh) / 2 - 78vw) * -1),
    -100vw calc(((300vw - 100vh) / 2 - 78vw) * -1), -100vw calc(((300vw - 100vh) / 2 - 78vw) * -1);
}

.secondary-btn {
  background-color: #f2f2f2 !important;
  border: 1px solid #f2f2f2 !important;

  &:hover {
    box-shadow: -1px 15px 23px -10px #c7c5c5 !important;
  }

  &:active,
  &:focus {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    box-shadow: none !important; // Remove the default focus outline
  }
}

.dropdown-item {
  width: 100% !important;
}

// .app-content {
// 	background-color: white;
// }

// prettier-ignore 
html .navbar-hidden .app-content {
  padding-top: 0px !important;

  @media (max-width: (map-get($grid-breakpoints, md))) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 60px !important;
  }
}

.not-read-only[readonly] {
  opacity: 1 !important;
}

// prettier-ignore 
#table-container {
  @media (max-width: (map-get($grid-breakpoints, md))) {
    width: 120vw;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  /* Adjust as needed */
}

.fs-7 {
  font-size: 0.85rem;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-card-image {
  width: 50%;
  // height: 250px;
  object-fit: "cover";

  // @media (max-width:500px) {
  // 	height: 20%;
  // 	object-fit: "cover";
  // }
}


/* Ocultar flechas en Chrome, Safari, Edge y Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Ocultar flechas en Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Tamaños de fuente predeterminados */
@media (max-width: (map-get($grid-breakpoints, sm))) {
  .fs-xs-1 {
    font-size: 0.75rem !important;
  }

  .fs-xs-2 {
    font-size: 0.875rem !important;
  }

  .fs-xs-3 {
    font-size: 1rem !important;
  }

  .fs-xs-4 {
    font-size: 1.25rem !important;
  }

  .fs-xs-5 {
    font-size: 1.5rem !important;
  }

  .fs-xs-6 {
    font-size: 2rem !important;
  }
}

/* Tamaños de fuente para pantallas pequeñas (sm) */
@media (min-width: (map-get($grid-breakpoints, sm))) {
  .fs-sm-1 {
    font-size: 0.75rem !important;
  }

  .fs-sm-2 {
    font-size: 0.875rem !important;
  }

  .fs-sm-3 {
    font-size: 1rem !important;
  }

  .fs-sm-4 {
    font-size: 1.25rem !important;
  }

  .fs-sm-5 {
    font-size: 1.5rem !important;
  }

  .fs-sm-6 {
    font-size: 2rem !important;
  }
}

/* Tamaños de fuente para pantallas medianas (md) */
@media (min-width: (map-get($grid-breakpoints, md))) {
  .fs-md-1 {
    font-size: 0.75rem !important;
  }

  .fs-md-2 {
    font-size: 0.875rem !important;
  }

  .fs-md-3 {
    font-size: 1rem !important;
  }

  .fs-md-4 {
    font-size: 1.2rem !important;
  }

  .fs-md-5 {
    font-size: 1.5rem !important;
  }

  .fs-md-6 {
    font-size: 2rem !important;
  }
}

/* Tamaños de fuente para pantallas grandes (lg) */
@media (min-width: (map-get($grid-breakpoints, lg))) {
  .fs-lg-1 {
    font-size: 0.75rem !important;
  }

  .fs-lg-2 {
    font-size: 0.875rem !important;
  }

  .fs-lg-3 {
    font-size: 1rem !important;
  }

  .fs-lg-4 {
    font-size: 1.25rem !important;
  }

  .fs-lg-5 {
    font-size: 1.5rem !important;
  }

  .fs-lg-6 {
    font-size: 2rem !important;
  }
}

/* Tamaños de fuente para pantallas extra grandes (xl) */
@media (min-width: (map-get($grid-breakpoints, xl))) {
  .fs-xl-1 {
    font-size: 0.75rem !important;
  }

  .fs-xl-2 {
    font-size: 0.875rem !important;
  }

  .fs-xl-3 {
    font-size: 1rem !important;
  }

  .fs-xl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xl-5 {
    font-size: 1.5rem !important;
  }

  .fs-xl-6 {
    font-size: 2rem !important;
  }
}

/* Tamaños de fuente para pantallas extra extra grandes (xxl) */
@media (min-width: 1400px) {
  .fs-xxl-1 {
    font-size: 0.75rem !important;
  }

  .fs-xxl-2 {
    font-size: 0.875rem !important;
  }

  .fs-xxl-3 {
    font-size: 1rem !important;
  }

  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-5 {
    font-size: 1.5rem !important;
  }

  .fs-xxl-6 {
    font-size: 2rem !important;
  }
}

.book-screen {
  p {
    font-size: 14px !important;
    margin-bottom: 0px;
    color: #525255;
    line-height: 17px;
  }

  .MuiOutlinedInput-root {
    font-size: 39px !important;
  }

  button.disabled {
    background-color: #F3F3F3 !important;
  }
}

.book-content {
  @media (min-width: 767px) {
    max-width: 800px;
  }
}

.time-button.btn-outline-secondary {
  border: 1px solid #dcdae2 !important;
}

/* Archivo styles.css */
.vertical-menu-icons {
  width: 18px !important;
  height: 18px !important;
}

.sidenav-overlay {
  z-index: 1000;
}

#table-empty-message {
  @media (max-width: 767px) {
    width: calc(100vw - 70px);
    // margin-left: 15px;
    margin-bottom: calc(100vh - 505px);
  }

  width: 100%;
}

.stadistic-card-img {
  @media (min-width: 767px) {
    width: 46px;
    height: 46px;
  }

  width: 32px;
  height: 32px;
}

.brand-logo {
  position: absolute;
  top: 2rem;
  left: 0rem;
  margin: 0;
  z-index: 1;
  justify-content: unset;
  display: flex;
}


.MuiTelInput-IconButton {
  @media (max-width: 767px) {
    padding-left: 5px !important;
    padding-right: 0px !important;
  }

}

$app-input-height: 40px;

.app-input:not(textarea) {
  .MuiInputBase-root {
    @media (max-width: 767px) {
      height: calc($app-input-height - 1px) !important;
    }

    height: 45px !important;
    padding-left: 5px;
  }

  @media (max-width: 767px) {
    height: $app-input-height;
  }

  height: 44px;
}

.scheduled-rounded-button {

  height: 40px;
  width: 40px;

  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
  }
}




html .navbar-hidden .app-content:has(.home-screen) {
  // #home-screen {
  padding-left: 0px !important;
  padding-right: 0px !important;
  // }
}

.main-content {
  @media (max-width: (map-get($grid-breakpoints, sm))) {
    padding-bottom: $fixed-bottom-navigato-height ;
  }

  @media (min-width: (map-get($grid-breakpoints, sm))) and (max-width: (map-get($grid-breakpoints, xl))) {
    padding-bottom: $fixed-bottom-navigato-height-sm;

  }

}

.fixed-bottom-navigator {

  @media (max-width: (map-get($grid-breakpoints, md))) {

    height: $fixed-bottom-navigato-height !important;
  }

  height: $fixed-bottom-navigato-height-sm !important;

}

.main-content-height {
  @media (max-width: (map-get($grid-breakpoints, sm))) {
    height: calc(100vh - $fixed-bottom-navigato-height);
  }

  height: calc(100vh - $fixed-bottom-navigato-height-sm);

}

.infinite-scroll-component {
  @media (max-width: (map-get($grid-breakpoints, sm))) {
    padding-bottom: $fixed-bottom-navigato-height +10;
  }

  @media (min-width: (map-get($grid-breakpoints, sm))) and (max-width: (map-get($grid-breakpoints, xl))) {
    padding-bottom: $fixed-bottom-navigato-height +10;

  }
}

body,
#root,
.wrapper:has(.car-mode-screen) {
  height: auto;
}

.wrapper:has(#card-mode-screen) .scroll-top {
  bottom: 90px;
  right: 18px;
}